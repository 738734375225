@media screen and (max-width: 900px) {
    .appHeaderDrawer-cont {

        &__title {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px
        }

        &__body {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            height: 100%;

            &__text {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }

            &__avatarWrapper {
                width: 100%;
                display: flex;
                gap: 12px;
                align-items: center;

            }

            &__authWrapper {
                width: 100%;
                display: flex;
                gap: 8px;
                margin-top: auto;
            }
        }

        &__unAuth-body {
            display: flex;
            width: 100%;
            justify-content: center;
            gap: 12px;
            flex-direction: column;
        }

    }
}